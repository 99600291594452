<template>
  <div class='safetyCircuit mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="csDiv mb10">
      <el-button type="primary" size="small" @click="refreshLine()">重新生成</el-button>
    </div>
    <div class="list" :style="{height:tableH+'px'}">
      <relation-graph-map ref="graphmapRef"></relation-graph-map>
    </div>
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb'
import relationGraphMap from '@/components/relation-graph-map'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-circuit',
  data() {
    return {
      //筛选数据
      keyWord :{
        
      },

      dicObj:{
        homeList:[],//场所列表
      },
      tableH :'',//表格高度
      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    breadCrumb,
    relationGraphMap,
  },
  watch:{
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.$nextTick(() => {
          vm.$refs.graphmapRef.init()
        })
      }
    }
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  async mounted(){
    if(this.$store.state.homeId!=null){
      vm.$nextTick(() => {
        vm.$refs.graphmapRef.init()
      })
    }
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //重新生成
    refreshLine(){
      vm.$refs.graphmapRef.init()
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-150
    }
  }
}
</script>
<style lang='scss'>
.safetyCircuit{
  
}
</style>
