<template>
  <div class="relationGraphMap" @click="handleOuterClick">
    <div class="iconTips">
      <!-- <div class="item"><i class="icon icon-hengxian warning"></i>预警</div> -->
      <!-- <div class="item"><i class="icon icon-hengxian error"></i>故障</div> -->
      <div class="item"><i class="icon icon-duanluqi info"></i>离线</div>
      <div class="item"><i class="icon icon-duanluqi primary"></i>在线</div>
      <div class="item"><i class="icon icon-duankai success"></i>断开</div>
      <div class="item"><i class="icon icon-bihe success"></i>闭合</div>
      <div class="item"><i class="icon icon-gaojing error"></i>告警</div>
      <div class="item"><i class="icon icon-chazuo primary"></i>在线</div>
      <div class="item"><i class="icon icon-chazuo info"></i>离线</div>
    </div>
    <RelationGraph
        ref="graphRef"
        :options="graphOptions">
      <template #node="{node}">
        <div class="nodeCont" @click.stop="clickNode(node)">
          <div class="switch">
            <i class="icon icon-bihe" v-if="node.data.switchStatus==1"></i>
            <i class="icon icon-duankai" v-else></i>
          </div>
          <div class="device">
            <i class="icon icon-duanluqi" :class="{'primary':node.data.status==1,'info':node.data.status==0}" v-if="node.data.nodeType=='1'"></i>
            <i class="icon icon-chazuo" :class="{'primary':node.data.status==1,'info':node.data.status==0}" v-else></i>
          </div>
          <div class="cont">
            <div class="name" :title="node.data.name">{{ node.data.name }}</div>
            <!-- <el-divider :class="{'alarm':node.data.alarmStatus==1}"></el-divider> -->
            <!-- <div class="code">{{ node.data.marketCode }}</div> -->
          </div>
          <div v-if="node.data.alarmStatus==1" class="alarm icon icon-gaojing error"></div>
          <el-button v-if="currNode == node.id" class="btn" type="primary" plain size="small" @click.stop="nodeClick(node.data)">设备数据</el-button>
        </div>
      </template>
    </RelationGraph>
    <circuit-detail ref="detailDio"></circuit-detail>
  </div>
</template>

<script>
import circuitDetail from '@/views/safety/circuit/circuit-detail'
import RelationGraph from 'relation-graph';
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
export default {
  data() {
    return {
      currNode: -2,//当前点击的节点
      //图谱配置
      graphOptions: {
        backgroundColor:'#000',//图谱背景颜色
        allowSwitchLineShape: false,//是否在工具栏中显示切换线条形状的按钮
        allowSwitchJunctionPoint: false,//是否在工具栏中显示切换连接点位置的按钮
        allowAutoLayoutIfSupport:true,//是否在工具栏中显示【自动布局】按钮
        allowShowRefreshButton:true,//是否在工具栏中显示【刷新】按钮
        allowShowDownloadButton:true,//是否在工具栏中显示【下载】按钮
        allowShowZoomMenu:false,//是否在右侧菜单栏显示放大缩小的按钮
        disableDragNode:true,//是否禁用图谱中节点的拖动
        disableNodeClickEffect:true,//是否禁用节点默认的点击效果（选中、闪烁）
        disableLineClickEffect:true,//是否禁用线条默认的点击效果（选中、闪烁）
        moveToCenterWhenRefresh:true,//当图谱刷新后,让图谱根据节点居中
        zoomToFitWhenRefresh:true,//当图谱刷新后,是否让图谱缩放到适合可见区域大小
        useAnimationWhenRefresh:true,//当图谱刷新后,使用动画让图居中、缩放
        defaultFocusRootNode:false,//默认为根节点添加一个被选中的样式
        // defaultNodeWidth:200,//默认的节点宽度
        // defaultNodeHeight:100,//默认的节点高度
        defaultLineColor: 'rgba(64, 158, 255, 1)',//默认的线条颜色
        defaultNodeColor: 'transparent',//默认的节点背景颜色
        defaultNodeBorderWidth: 0,//默认的节点边框粗细
        defaultNodeBorderColor: 'rgba(255, 255, 255, 0.3)',//默认的节点边框颜色
        defaultNodeFontColor: '#ffffff',//默认的节点文字颜色
        defaultNodeShape: 1,//默认的节点形状，0:圆形；1:矩形
        toolBarDirection: 'v',//工具栏方向，h:水平；v:垂直
        toolBarPositionH: 'right',//工具栏水平位置，left:左；right:右
        toolBarPositionV: 'top',//工具栏垂直位置，top:上；bottom:下
        defaultPloyLineRadius: 0,//默认折线拐点
        defaultLineShape: 4,//默认的线条样式（1:直线/2:样式2/3:样式3/4:折线/5:样式5/6:样式6）
        defaultJunctionPoint: 'lr',//默认的连线与节点接触的方式（border:边缘/ltrb:上下左右/tb:上下/lr:左右）
        layout: {
          layoutName: 'tree',//布局方式（tree树状布局/center中心布局/force自动布局）
          from: 'left',//left:从左到右/top:从上到下/right:从右到左/bottom:从下到上
          min_per_width: 300, // 节点距离限制:节点之间横向距离最小值
          min_per_height: 100,//节点距离限制:节点之间纵向距离最小值
        },
        //默认的线条箭头样式
        defaultLineMarker: {
          "markerWidth": 0,
          "markerHeight": 0,
          "refX": 0,
          "refY": 0,
          "data": ""
        },
      },
      electricData:[],//电气线路数据
      json_data:null,
    };
  },
  watch:{
    '$store.state.wsMsg':function(newVal){
      this.getSkMsg(newVal)
    },
  },
  components:{
    circuitDetail,
    RelationGraph
  },
  mounted() {
    // this.init()
  },
  methods: {
    //websocket接收
    getSkMsg(res) {
      //设备告警通知
      if(res?.type=='deviceAlarm'){
        let str = this.json_data.nodes?.find((li)=>{
          return li.data?.id == res.data.deviceData.deviceId;//筛选出匹配数据
        });
        if(str){
          str.data.alarmStatus = 1
        }
      }
      //设备状态通知
      if(res?.type=='deviceStatus'){
        let str = this.json_data.nodes?.find((li)=>{
          return li.data?.id == res.data.deviceData.deviceId;//筛选出匹配数据
        });
        if(str){
          str.data.status = res.data.deviceData.deviceStatus
        }
      }
      //设备跳闸通知
      if(res?.type=='deviceJump'){
        let str = this.json_data.nodes?.find((li)=>{
          return li.data?.id == res.data.deviceData.deviceId;//筛选出匹配数据
        });
        if(str){
          str.data.switchStatus = res.data.deviceData.trip
        }
      }
    },
    async init(){
      this.electricData = []
      await this.getElectricLine()
      if(this.electricData.length == 0){
        this.json_data = {
          rootId:-1,
          nodes:[{ 'id':'-1','text':'','opacity':0,fixed:true}],
          lines:[]
        }
        this.$refs.graphRef.setJsonData(this.json_data);
        return
      }
      this.json_data = {
        rootId:-1,
        nodes:[{ 'id':'-1','text':'','opacity':0,fixed:true}],
        lines:[{ 'from':'-1', 'to': this.electricData[0].id},]
      }
      for(var i in this.electricData){
        let obj = {
          id:this.electricData[i].id,
          text:'',
          data:this.electricData[i]
        }
        this.json_data.nodes.push(obj)
        if(this.electricData[i].parentId!='-1'){
          let obj2 = {
            'from': this.electricData[i].parentId,
            'to': this.electricData[i].id
          }
          this.json_data.lines.push(obj2)
        }
      }
      this.json_data.nodes.forEach(node => {
        node.alignItems = 'left';
      })
      this.$refs.graphRef.setJsonData(this.json_data, (graphInstance) => {
        // 这些写上当图谱初始化完成后需要执行的代码
        console.log('load:',graphInstance);
      });
    },
    //获取所有节点数据
    async getElectricLine(){
      let dataObj = {
        'areaId':this.$store.state.csType,
        'homeId':this.$store.state.homeId,
      }
      const res = await apiUrl.getElectricLine(qs.stringify(dataObj))
      if(res?.code==200){
        this.electricData = res.data
      }else{
        this.$message.error(res.message)
      }
    },
    //点击节点
    clickNode(node) {
      if(node.id != -1){
        this.currNode = node.id
      }
    },
    //点击查看节点数据
    nodeClick(item){
      this.currNode = -2
      this.$refs.detailDio.init('detail',item)
    },
    //点击节点外的canvas区域，关闭按钮
    handleOuterClick(event){
      let target = event.target;
      if (target.localName=='canvas') {
        this.currNode = -2
      }
    },
  }
};
</script>
<style lang="scss">
.relationGraphMap{
  width: 100%;
  height: 100%;
  position: relative;
  .relation-graph .rel-toolbar{
    z-index: 10;
  }
  .iconTips{
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    color: #fff;
    .item{
      margin: 5px 0;
      i{
        margin-right: 5px;
      }
    }
  }
  .rel-node-peel{
    padding: 8px 0;
  }
  .rel-toolbar{
    color: #fff;
    .c-mb-button{
      color: #fff;
    }
  }
  .nodeCont{
    display: flex;
    align-items: center;
    position: relative;
    .btn{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    .switch{
      i{
        font-size: 26px;
        color: #67C23A;
      }
    }
    .device{
      margin: 0 5px;
      i{
        font-size: 40px;
      }
    }
    .cont{
      width: 120px;
      .name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
      }
      .el-divider{
        margin: 5px 0;
        &.alarm{
          background-color: #F56C6C;
        }
      }

    }
    .alarm{
      font-size: 20px;
    }
  }
}
</style>
