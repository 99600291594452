<template>
  <div class="breadCrumb">
    <el-breadcrumb class="breadcrumb-container" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }" title="返回首页"><i class="icon icon-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in levelList" :key="item.menuUrl" :to="item.menuUrl">{{item.menuName}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import {findParent} from '@/utils/utils'
export default {
  name: 'Navbar',
  data() {
    return {
      levelList: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created(){
    this.getBreadcrumb()
  },
  methods:{
    getBreadcrumb() {
      let menuData = this.vtp.getSession('menuData')
      let routerUrl = this.$route.path
      let result = []
      findParent(menuData, routerUrl, result)
      this.levelList = result
      // console.log(result);
      // let matched = this.$route.matched.filter(item => item.name)
      // const first = matched[0];
      // if (first && first.name.trim().toLocaleLowerCase() !== 'Dashboard'.toLocaleLowerCase()) {
      //   matched = [{ path: '/dashboard', meta: { title: 'dashboard' }}].concat(matched)
      // }
      // this.levelList = matched
    }
  }
}
</script>
<style lang="scss">
.breadCrumb{
  margin-bottom: 10px;
  .el-breadcrumb__item:first-child,.el-breadcrumb__item:last-child{
    .el-breadcrumb__inner{
      color: #fff;
      font-weight: normal;
    }
  }
  .el-breadcrumb__inner{
    color: #fff;
  }
  .breadcrumb-container{
    display: flex;
    align-items: self-end;
  }
  // .el-breadcrumb__inner,.el-breadcrumb__item:last-child .el-breadcrumb__inner, 
  // .el-breadcrumb__item:last-child .el-breadcrumb__inner a, 
  // .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, 
  // .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
  //   color: #bbb;
  // }
}
</style>